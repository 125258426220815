

<template>
  <div>
    <div class="swiper-container">
      <swiper :options="swiperOption" ref="swiperSchool" v-if="swiperSchoolList.length">
        <swiper-slide v-for="(item, index) in swiperSchoolList" :key="index" class="slide1" >
          <div class="swiper-slide_inner" @click="itemClick(item)">
            <div class="cover" v-if="item.schoolIntroduceDO">
              <img :src="item.schoolIntroduceDO.iconPath" alt="">
            </div>
            <div class="text">
              <div class="txt oneRowTxt">{{ item.shortName }}</div>
              <div class="txt oneRowTxt">{{ item.phone }}</div>
            </div>
          </div>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
      <div class="swiper-button-prev prev2"></div>
      <div class="swiper-button-next next2"></div>

      <!-- <div class="swiper-button-prev2" slot="button-prev"> </div> -->
      <!-- <div class="swiper-button-next2" slot="button-next"> </div> -->

    </div>

  </div>
</template>

<script>
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  // components: { swiper, swiperSlide },
  props: ['swiperSchoolList'],
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: 2000,
        slidesPerView: 'auto',
        speed: 600,
        slidesPerView: 4,
        spaceBetween: 16,
        preventClicks: false,
        loopedSlides :4,
        paginationClickable: true,
        navigation: {
          prevEl: '.swiper-button-prev.prev2',
          nextEl: '.swiper-button-next.next2'
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.swiperSchool.swiper
    }
  },
 
  methods: {
    itemClick(item) {
      this.$router.push({
        name: 'Drivedetail',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.slide1 {
  transition: .2s;

  &:hover {
    transform: scale(1.05);
  }
}

.swiper-container {
  height: 351px;
}

.swiper-slide_inner {
  width: 280px;
  height: 313px;
  // box-shadow: 0px 0px 3px 0px rgba(148, 148, 148, 0.6);
  border: 1px solid rgba(148, 148, 148, 0.6);
  box-sizing: border-box;
  // overflow: hidden;

  .cover {
    width: 100%;
    background: #000;
    height: 220px;

    img {
      width: 100%;
      // height: 100%;
      display: block;
    }
  }

  .text {
    padding: 20px 0 0 20px;
    // border: 1px solid #949494;
    box-sizing: border-box;

    // border-top: none;
    .txt {
      font-size: 18px;
      color: #333;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}

.swiper-button-prev.prev2 {
  background: url('../assets/images/swiperLeft.png') no-repeat;
  background-size: 27px 44px;
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  left: 30px;
  // margin-left: 20px;
  z-index: 10;
  cursor: pointer;
}

.swiper-button-next.next2 {
  background: url('../assets/images/swiperRight.png')no-repeat;
  background-size: 27px 44px;
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  right: 30px;
  // margin-right: 20px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
}</style>