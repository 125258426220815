

<template>
  <div>
    <div class="swiper-container">
      <swiper :options="swiperOption" ref="myswiper">
        <swiper-slide v-for="(item, index) in swiperCoachlList" :key="index" >
          <div class="swiper-slide_inner">
            <div class="cover">
              <img :src="item.photoPath" alt="">
              <div class="text">
                <div class="txt oneRowTxt">{{ item.name }}  <span style="margin-left: 10px;">{{ item.teachCarType }}</span></div>
                <div class="txt oneRowTxt">{{ item.schoolName }}</div>
              </div>
            </div>

          </div>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
      <div class="swiper-button-prev prev3"></div>
      <div class="swiper-button-next next3"></div>

    </div>

  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: { swiper, swiperSlide },
  props: ['swiperCoachlList'],
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: 3000,
        speed: 1000,
        slidesPerView: 4,
        spaceBetween: 16,
        loopedSlides :4,
        paginationClickable: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          prevEl: '.swiper-button-prev.prev3',
          nextEl: '.swiper-button-next.next3'
        }
      },
    }
  },
  monted() {

  }
}
</script>

<style lang="scss" scoped>

.swiper-slide_inner {
  // width: 232px;
  // height: 314px;
  width: 280px;
  height: 313px;
  border: 1px solid rgba(148, 148, 148, 0.4);
  box-sizing: border-box;
  
  // overflow: hidden;


  .cover {
    width: 100%;
    background: #000;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      // height: 100%;
      display: block;
    }

    .text {
      padding: 20px 0 0 20px;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 96px;
      z-index: 99;
      background: rgba(236,157,75, 0.7);
      .txt {
        font-size: 18px;
        color: #fff;

        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }


}

.swiper-button-prev.prev3 {
  background: url('../assets/images/swiperLeft.png');
  background-size: 27px 44px;
  margin-left: 20px;
}

.swiper-button-next.next3 {
  background: url('../assets/images/swiperRight.png');
  background-size: 27px 44px;
  margin-right: 20px;

}
</style>