<template>
  <div class="distribution-map">
    <div class="dismap-container">
      <div class="dismap" ref="dismapchart"></div>
    </div>
  </div>
</template>

<script>
// echarts.js
import * as echarts from "echarts/lib/echarts";
//引入地图
import "echarts/lib/chart/map";
//引入插件
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/geo";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/visualMap";
import "echarts/lib/component/title";
import { ScatterChart } from "echarts/charts";
echarts.use([ScatterChart]);
// 引入洛阳市地图数据(引入方式重点)
import mapJson from "../assets/luoyang.json";
let regions = [
  //对不同的区块进行着色
  {
    name: "老城区", //区块名称
    LngAndLat: [112.477298,34.682945],
    itemStyle: {
      normal: {
        areaColor: "#EC9D4B",
      },
    },
  },
  {
    name: "西工区", //区块名称
    LngAndLat: [112.443232,34.667847],
    itemStyle: {
      normal: {
        areaColor: "#FBE5F1",
      },
    },
  },
  {
    name: "瀍河回族区", //区块名称
    LngAndLat: [112.491625, 34.684738],
    itemStyle: {
      normal: {
        areaColor: "#93cadc",
      },
    },
  },
  {
    name: "涧西区", //区块名称
    LngAndLat: [112.399243, 34.654251],
    itemStyle: {
      normal: {
        areaColor: "#D4ECB1",
      },
    },
  },
  {
    name: "孟津区", //区块名称
    LngAndLat: [112.443892, 34.826485],
    itemStyle: {
      normal: {
        areaColor: "#D4ECB1",
      },
    },
  },
  {
    name: "洛龙区", //区块名称,
    LngAndLat: [112.456634, 34.618557],
    itemStyle: {
      normal: {
        areaColor: "#93cadc",
      },
    },
  },
  {
    name: "新安县", //区块名称,
    LngAndLat: [112.141403, 34.728679],
    itemStyle: {
      normal: {
        areaColor: "#F08B94",
      },
    },
  },
  {
    name: "栾川县", //区块名称,
    LngAndLat: [111.618386, 33.783195],
    itemStyle: {
      normal: {
        areaColor: "#F08B94",
      },
    },
  },
  {
    name: "嵩县", //区块名称,
    LngAndLat: [112.087765, 34.131563],
    itemStyle: {
      normal: {
        areaColor: "#D4ECB1",
      },
    },
  },
  {
    name: "汝阳县", //区块名称,
    LngAndLat: [112.473789, 34.15323],
    itemStyle: {
      normal: {
        areaColor: "#F08B94",
      },
    },
  },
  {
    name: "宜阳县", //区块名称,
    LngAndLat: [112.179989, 34.516478],
    itemStyle: {
      normal: {
        areaColor: "#FBE5F1",
      },
    },
  },
  {
    name: "洛宁县", //区块名称,
    LngAndLat: [111.655399,  34.387179],
    itemStyle: {
      normal: {
        areaColor: "#93cadc",
      },
    },
  },
  {
    name: "伊川县", //区块名称,
    LngAndLat: [112.429384, 34.423416],
    itemStyle: {
      normal: {
        areaColor: "#e6f3f7",
      },
    },
  },
  {
    name: "偃师区", //区块名称,
    LngAndLat: [112.787739, 34.723042],
    itemStyle: {
      normal: {
        areaColor: "#FBE5F1",
      },
      emphasis: {
        areaColor: "#F7F77A",
      }
    },
  },
  
];

export default {
  name: "DistributionMap",
  props: {
    sanData: {
      type: Array,
      default: function () {
        return [
          { name: "老城区", LngAndLat: [112.477298,34.682945], num: 0 },
          { name: "西工区", LngAndLat: [112.443232,34.667847], num: 0 },
          { name: "瀍河回族区", LngAndLat: [112.491625, 34.684738], num: 0 },
          { name: "涧西区", LngAndLat: [112.399243, 34.654251], num: 0 },
          { name: "孟津区", LngAndLat: [112.443892, 34.826485], num: 0 },
          { name: "洛龙区", LngAndLat: [112.456634, 34.618557], num: 0 },
          { name: "新安县", LngAndLat: [112.141403, 34.728679], num: 0 },
          { name: "栾川县", LngAndLat: [111.618386, 33.783195], num: 0 },
          { name: "嵩县", LngAndLat: [112.087765, 34.131563], num: 0 },
          { name: "汝阳县", LngAndLat: [112.473789, 34.15323], num: 0 },
          { name: "宜阳县", LngAndLat: [112.179989, 34.516478], num: 0 },
          { name: "洛宁县", LngAndLat: [111.655399,  34.387179], num: 0 },
          { name: "伊川县", LngAndLat: [112.429384, 34.423416], num: 0 },
          { name: "偃师区", LngAndLat: [112.787739, 34.723042], num: 9 },
        ]
      }
    }
  },
  data() {
    return {
      geo: {
        // 地图配置
        show: true,
        map: "louyang",
        label: {
          normal: {
            show: true, // 文字显示
            textStyle: {
                color: "#333", //文字颜色
                fontSize: 12, //文字大小
              }
          },
          emphasis: {
            show: false,
           
          },
        },
        roam: false,
        itemStyle: {
          normal: {
            // borderWidth: 1,
            borderColor: '#fff', //区域边框色
          },
          emphasis: {
            // 选中样式
            borderWidth: 1,
            borderColor: '#fff',
          },
        },
        regions: regions,
        zoom: 1.2,
      },
      
      // 地域charts实例
      dismapchart: {},
    };
  },
  watch: {
    //观察option的变化
    sanData: {
      handler(newVal, oldVal) {
        if (this.dismapchart) {
          if (newVal) {
            this.dismapchart.dispose();
            this.dismapchart = null;
            this.initCharts();
          }
        }
      },
      deep: true, //对象内部属性的监听，关键。
    },
  },
  mounted() {
    regions = regions.map((item)=>{
    this.$set(item.itemStyle, 'emphasis', {areaColor : '#F7F77A'})
      return item
    })
    this.initCharts();

  },
  beforeDestroy() {
    if (!this.dismapchart) {
      return;
    }
    this.dismapchart.dispose();
    this.dismapchart = null;
  },
  methods: {
    convertData(data) {
      if (!data) return
      // 处理数据函数
      var res = [];
      for (var i = 0; i < data.length; i++) {
        var geoCoord = data[i].LngAndLat;
        if (geoCoord) {
          res.push({
            name: data[i].name,
            num: data[i].num,
            value: geoCoord.concat(data[i].value),
          });
        }
      }
      return res;
    },
    initCharts() {
      const that = this;
      let mapDom = this.$refs.dismapchart;
      this.dismapchart = echarts.init(mapDom);
      //这个是关键，之前拿到的杭州各街道数据
      echarts.registerMap("louyang", mapJson, {});
      let myOption = {
        // echarts 配置
        tooltip: {
          trigger: "item",
          
        },
        geo: this.geo,
        series: [
          {
            // 散点配置
            tooltip: {
              trigger: "item",
              confine: false,
              formatter: (p) => {
                let dataCon = p.data;
                let txtCon = dataCon.name;
                return txtCon+'：'+ dataCon.num;
              },
            },
            name: "",
            type: "scatter",
            symbol: "pin",
            symbolSize: 40,
            coordinateSystem: "geo",
            data: this.convertData(this.sanData),
            showEffectOn: "emphasis",
            rippleEffect: {
              brushType: "stroke",
            },
            
            hoverAnimation: true,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return  params.data["num"]; //大头针标记上显示的信息
                },
                position: "inside",
                fontSize: 12,
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                color: "#FEC006",
              },
            },
          },
        ],
      };
      this.dismapchart.setOption(myOption);
      //   实现了图表跟随窗口大小自适应的需求
      window.addEventListener("resize", this.dismapchart.resize);
      //单击事件
      this.dismapchart.on("click", function (params) {
        // if (params.value) {
        //   centerPoint = params.value;
        // } else {
        //   centerPoint = params.region.LngAndLat;
        // }
        that.$router.push({
          name:'DrivingMap',
          query: {
            city: params.name
          }
        })
        // window.open(
        //   `https://api.map.baidu.com/geocoder?address=${params.name}驾校&output=html&region=杭州&src=我的位置`,
        //   "_blank"
        // );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dismap {
  width: 690px;
  height: 520px;
}
</style>