<template>
  <div class="home">
    <my-swiper :bannerList="bannerList" :pagination="false"></my-swiper>
    <div class="home-evaluate">
      <div class="home-title">驾培行业服务综合评价</div>
      <div class="evaluate" v-if="homeEvaluate">
        <div class="eva-total">
          <p class="total-title">评价总数</p>
          <div class="total-info">
            <p class="info-num">{{homeEvaluate.total}}</p>
            <p class="info-name">条</p>
          </div>
        </div>
        <div class="eva-total eva-center">
          <p class="total-title">收到差评数</p>
          <div class="total-info">
            <p class="info-num">{{homeEvaluate.badComment}}</p>
            <p class="info-name">条</p>
          </div>
        </div>
        <div class="eva-total eva-center">
          <p class="total-title">差评整改率</p>
          <div class="total-info">
            <p class="info-num">{{homeEvaluate.badCommentRate?(homeEvaluate.badCommentRate).toFixed(0):0}}</p>
            <p class="info-name">%</p>
          </div>
        </div>
        <div class="eva-average">
          <p class="total-title">平均分</p>
          <div class="total-info">
            <p class="info-num">{{ homeEvaluate.avgScore? (homeEvaluate.avgScore).toFixed(2):0}}</p>
            <p class="info-name">分</p>
          </div>
        </div>
      </div>
    </div>
    <div class="zlxc-container" style="overflow: visible">
      <div class="home-layout" v-if="censusListDate">
        <div class="home-title">
          <p class="title-main">洛阳市驾培机构分布图</p>
          <p class="title-sub">汇集洛阳市所有驾校</p>
        </div>
        <div class="home-module">
          <div class="module-left">
            <!-- 地图组件 -->
            <dstributiond-map :sanData="sanData"></dstributiond-map>
          </div>
          <div class="module-right">
            <div class="right-top">
              <p class="top-title">驾培实时数据</p>
              <ul class="top-list">
                <li class="list-li">
                  <p class="li-name">驾校</p>
                  <p class="li-num">{{censusListDate.schoolTotal}}家</p>
                </li>
                <li class="list-li">
                  <p class="li-name">教练员</p>
                  <p class="li-num">{{censusListDate.coachTotal}}人</p>
                </li>
                <li class="list-li">
                  <p class="li-name">教练车</p>
                  <p class="li-num">{{censusListDate.carTotal}}辆</p>
                </li>
              </ul>
            </div>
            <div class="right-bottom">
              <ul class="bottom-list">
                <li class="list-li">
                  <div class="li-img-cistern" :title="censusListDate.studentTotal + '人'">
                    <img
                      class="li-img"
                      src="../../assets/zlxcimgs/home_image_xuejiaren@2x.png"
                    >
                    <p class="li-num">{{censusListDate.studentTotal > 9999 ? (censusListDate.studentTotal/10000).toFixed(2) + '万' : censusListDate.studentTotal}}<span>人</span></p>
                  </div>
                  <p class="li-name">学驾人</p>
                </li>
                <li class="list-li">
                  <div class="li-img-cistern" :title="censusListDate.studentTodayTotal + '人'">
                    <img
                      class="li-img"
                      src="../../assets/zlxcimgs/home_image_jinribaom@2x.png"
                    >
                    <p class="li-num">{{censusListDate.studentTodayTotal}}<span>人</span></p>
                  </div>
                  <p class="li-name">今日报名</p>
                </li>
                <li class="list-li">
                  <div class="li-img-cistern" :title="censusListDate.studentTrainingTotal + '人'">
                    <img
                      class="li-img"
                      src="../../assets/zlxcimgs/home_image_jinriprixun@2x.png"
                    >
                    <p class="li-num">{{censusListDate.studentTrainingTotal}}<span>人</span></p>
                  </div>
                  <p class="li-name">今日培训</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="home-layout" v-if="swiperSchoolList.length">
        <div class="home-link">
          <p class="title-main">驾校风采</p>
          <router-link
            tag="div"
            class="title-link"
            to="/recruitment/driveschool"
          >
            <p class="link-text">查看更多</p>
            <img
              class="link-img"
              src="../../assets/zlxcimgs/home_icon_xiayige@2x.png"
            >
          </router-link>
        </div>
        <swiperSchool :swiperSchoolList="swiperSchoolList" key="2"/>
      </div>

      <div class="home-layout" v-if="swiperCoachlList.length">
        <div class="home-link">
          <p class="title-main">明星教练</p>
          <router-link
            tag="div"
            class="title-link"
            to="/recruitment/instructor"
          >
            <p class="link-text">查看更多</p>
            <img
              class="link-img"
              src="../../assets/zlxcimgs/home_icon_xiayige@2x.png"
            >
          </router-link>
        </div>
        <swiperCoach :swiperCoachlList="swiperCoachlList" key="1"/>
      </div>
      
      
      <div class="home-layout">
        <div class="home-link">
          <p class="title-main">行业动态</p>
          <router-link
            tag="div"
            class="title-link"
            to="/about"
          >
            <p class="link-text">查看更多</p>
            <img
              class="link-img"
              src="../../assets/zlxcimgs/home_icon_xiayige@2x.png"
            >
          </router-link>
        </div>
        <div class="home-module">
          <div class="module-center" v-if="dataSheetList.length > 0">
            <ul class="about-list">
              <li class="list-li1" v-for="item in dataSheetList" :key="item.id" @click="tapJumpDetail(item.id)">
                <div class="li-time">
                  <!-- {{item.createDate}} -->
                  <div class="year">{{ item.year }}</div>
                  <div class="date">{{ item.day }}</div>
                </div>
                <div class="li-info">
                  <!-- {{item.title}} -->
                  <div class="title oneRowTxt">{{item.title}}</div>
                  <!-- <div class="text towRowTxt" v-html="item.mainBody"> </div> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="home-layout">
        <div class="home-title">
          <p class="title-main">学车流程</p>
        </div>
        <div class="home-module">
          <div class="module-center">
            <ul class="drive-list">
              <li class="list-li">
                <p class="li-info">
                  <img
                    class="info-img"
                    src="../../assets/zlxcimgs/home_icon_wfindadrivingschool@2x.png"
                  >
                  <img
                    class="dis-info-img"
                    src="../../assets/zlxcimgs/home_icon_findadrivingschool@2x.png"
                  >
                </p>
                <p class="li-name">找驾校</p>
              </li>
              <li class="list-li">
                <p class="li-info">
                  <img
                    class="info-img"
                    src="../../assets/zlxcimgs/home_icon_winspect@2x.png"
                  >
                  <img
                    class="dis-info-img"
                    src="../../assets/zlxcimgs/home_icon_xinspect@2x.png"
                  >
                </p>
                <p class="li-name">体检</p>
              </li>
              <li class="list-li">
                <p class="li-info">
                  <img
                    class="info-img"
                    src="../../assets/zlxcimgs/home_icon_wfacetoface@2x.png"
                  >
                  <img
                    class="dis-info-img"
                    src="../../assets/zlxcimgs/home_icon_facetoface@2x.png"
                  >
                </p>
                <p class="li-name">面签</p>
              </li>
              <li class="list-li">
                <p class="li-info">
                  <img
                    class="info-img"
                    src="../../assets/zlxcimgs/home_icon_wsignup@2x.png"
                  >
                  <img
                    class="dis-info-img"
                    src="../../assets/zlxcimgs/home_icon_signup@2x.png"
                  >
                </p>
                <p class="li-name">报名缴费</p>
              </li>
              <li class="list-li">
                <p class="li-info">
                  <img
                    class="info-img"
                    src="../../assets/zlxcimgs/home_icon_wscience@2x.png"
                  >
                  <img
                    class="dis-info-img"
                    src="../../assets/zlxcimgs/home_icon_science@2x.png"
                  >
                </p>
                <p class="li-name">理科</p>
              </li>
              <li class="list-li">
                <p class="li-info">
                  <img
                    class="info-img"
                    src="../../assets/zlxcimgs/home_icon_wtechnicaldepartment@2x.png"
                  >
                  <img
                    class="dis-info-img"
                    src="../../assets/zlxcimgs/home_icon_technicaldepartment@2x.png"
                  >
                </p>
                <p class="li-name">实操训练</p>
              </li>
              <li class="list-li">
                <p class="li-info">
                  <img
                    class="info-img"
                    src="../../assets/zlxcimgs/home_icon_wscience@2x.png"
                  >
                  <img
                    class="dis-info-img"
                    src="../../assets/zlxcimgs/home_icon_science@2x.png"
                  >
                </p>
                <p class="li-name">科目四</p>
              </li>
              <li class="list-li">
                <p class="li-info">
                  <img
                    class="info-img"
                    src="../../assets/zlxcimgs/home_icon_wIssuecertificates@2x.png"
                  >
                  <img
                    class="dis-info-img"
                    src="../../assets/zlxcimgs/home_icon_Issuecertificates@2x.png"
                  >
                </p>
                <p class="li-name">发证</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div class="home-layout" v-if="hospitalListData.length > 0">
        <div class="home-link">
          <p class="title-main">体检网点</p>
          <router-link
            tag="div"
            class="title-link"
            to="/hmap"
          >
            <p class="link-text">查看更多</p>
            <img
              class="link-img"
              src="../../assets/zlxcimgs/home_icon_xiayige@2x.png"
            >
          </router-link>
        </div>
        <div class="home-module" >
          <div class="home-adress">
            <div class="adress" v-for="item in hospitalListData" :key="item.hospitalId">
              <div class="adress-icon">
                <img
                  class="icon-img"
                  src="../../assets/zlxcimgs/home_icon_daweizhi@2x.png"
                >
              </div>
              <div :class="['adress-ins', {action : map.center.lng == item.lng}]" @click="tapClickHospital(item)">
                <div class="ins-title">{{item.hospitalName}}</div>
                <p class="ins-adress">
                  <img
                    class="icon-img"
                    src="../../assets/zlxcimgs/home_icon_location@2x.png"
                  >
                  <span>{{item.address}}</span>
                </p>
                <div class="ins-orther">
                  <div class="date">
                    <img
                      class="icon-img"
                      src="../../assets/zlxcimgs/home_icon_time@2x.png"
                    >
                    <span>{{item.time}}</span>
                  </div>
                  <div class="phone">
                    <img
                      class="icon-img"
                      src="../../assets/zlxcimgs/home_icon_tell@2x.png"
                    >
                    <span>{{item.phone}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="home-map">
            <!-- 地图组件 -->
            <baidu-map :style="{width:map.width,height:map.height}" class="map" ak="kwCrzOkUIYBl1YV7Q0MPGqWLn4oW2Cje" :zoom="map.zoom" :center="{lng: map.center.lng, lat: map.center.lat}" @ready="handler" :scroll-wheel-zoom="true">
              <!-- <bm-marker v-for="marker of markers" :key="marker.code" :position="{lng: marker.lng, lat: marker.lat}" @click="lookDetail(marker)"></bm-marker> -->
              <bm-marker v-if="map.center.lng != 120.219375416" :position="{lng: map.center.lng, lat: map.center.lat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE" @click="lookDetail(map.center)">
                <bm-label :content="mapLabel" :position="{lng: 116.404, lat: 39.915}" :labelStyle="{color: '#333', fontSize : '18px', padding: '2px 8px', borderRadius: '8px'}" title="Hover me" :offset="{width: 0, height: 30}"/>
              </bm-marker>
            </baidu-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DstributiondMap from '@/components/dstributiondMap'
import swiperSchool from '@/components/swiperSchool'
import swiperCoach from '@/components/swiperCoach'
// 百度地图
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarkerClusterer from  'vue-baidu-map/components/extra/MarkerClusterer'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'
import BmLabel from 'vue-baidu-map/components/overlays/Label'
import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow'


import { queryIndexCollectList, queryGetHospitalList, queryIndexSchoolGroupList, querySimpleIndustryDynamics, queryHomeEvaluate, recommendSchoolList1, recommendCoachList } from '@/api/herbalMedicine'

import { formatTime } from '@/utils/common.js'

export default {
  name: 'Home',
  components: {
      DstributiondMap,
      BaiduMap,
      BmMarkerClusterer,
      BmMarker,
      BmLabel,
      BmInfoWindow,
      swiperSchool,
      swiperCoach
  },
  created () {
    // 明星教练
    this.getQueryQueryList()
    // 驾校风采数据
    this.getQueryTrainingSchoolList()
    // 首页驾培统计
    this.queryIndexCollectList()
    this.censusListRequest = setInterval(() => {
      // 首页驾培统计
      this.queryIndexCollectList()
    }, 300000);
    // 查询体检地址列表
    // this.geyQueryGetHospitalList()
    // 查询驾校数量
    this.geyQueryIndexSchoolGroupList()
    // 行业动态列表
    this.getQuerySimpleIndustryDynamics()
    // 首页评价相关
    this.getQueryHomeEvaluate()
    
    this.censusListRequest = setInterval(() => {
      // 首页评价相关
      this.getQueryHomeEvaluate()
    }, 300000);
  },
  beforeDestroy () {
    clearInterval(this.censusListRequest);
    this.censusListRequest = null
  },
  data () {
    return {
      // 首页评价相关
      homeEvaluate: null,
      // 地图散点数据
      sanData: null,
      // 驾培统计
      censusListDate: null,
      // 行业动态
      dataSheetList: [],
      bannerList: [
        {
          to: '/',
          img: require('@/assets/zlxcimgs/home_image_banner@2x.png'),
          mobileImg: require('@/assets/zlxcimgs/home_image_banner@2x.png'),
          info: ''
        },
        // {
        //   to: '/',
        //   img: require('@/assets/zlxcimgs/home_image_bannertwo@2x.png'),
        //   mobileImg: require('@/assets/zlxcimgs/home_image_bannertwo@2x.png'),
        //   info: ''
        // }
      ],
      swiperSchoolList: [],//驾校风采
      swiperCoachlList: [], //明星教练
      score: 4.5,
      map:{
        center: {lng:112.454040, lat:34.619682},//'洛阳市',112.477298, 34.682945
        zoom: 12,
        width:'100%',
        height:'100%'
      },
      mapLabel: '',
      markers: [],
      hospitalListData: []
    }
  },
  methods: {
    // 查询教练列表
    getQueryQueryList () {
      recommendCoachList({
        pageIndex: 1,
        pageSize: 50,
      }).then(res => {
        if (res.data.length > 0) {
          this.swiperCoachlList = res.data
        } else {
          this.showMessage('未找到结果', "error");
        }
      })
    },
    // 查询驾校列表
    getQueryTrainingSchoolList () {
      recommendSchoolList1({
        // isPublish: 1,
        pageIndex: 1,
        pagesize: 50,
      }).then(res => {
        if (res.data.length > 0) {
          this.swiperSchoolList = res.data
          console.log('优质驾校')
          console.log(this.swiperSchoolList)
        } else {
          this.showMessage('未找到结果', "error");
        }
      })
    },
    // 首页驾培统计
    queryIndexCollectList () {
      queryIndexCollectList({}).then(res => {
        this.censusListDate = res.data
      })
    },
    // 首页评价相关
    getQueryHomeEvaluate () {
      queryHomeEvaluate({}).then(res => {
        console.log('这是么子？')
        console.log(res)
        this.homeEvaluate = res.data
      })
    },
    // 查询驾校数量
    geyQueryIndexSchoolGroupList () {
      queryIndexSchoolGroupList({}).then(res => {
        let _sanData = [
          { name: "老城区", LngAndLat: [112.477298,34.682945], num: 0 },
          { name: "西工区", LngAndLat: [112.443232,34.667847], num: 0 },
          { name: "瀍河回族区", LngAndLat: [112.491625, 34.684738], num: 0 },
          { name: "涧西区", LngAndLat: [112.399243, 34.654251], num: 0 },
          { name: "孟津区", LngAndLat: [112.443892, 34.826485], num: 0 },
          { name: "洛龙区", LngAndLat: [112.456634, 34.618557], num: 0 },
          { name: "新安县", LngAndLat: [112.141403, 34.728679], num: 0 },
          { name: "栾川县", LngAndLat: [111.618386, 33.783195], num: 0 },
          { name: "嵩县", LngAndLat: [112.087765, 34.131563], num: 0 },
          { name: "汝阳县", LngAndLat: [112.473789, 34.15323], num: 0 },
          { name: "宜阳县", LngAndLat: [112.179989, 34.516478], num: 0 },
          { name: "洛宁县", LngAndLat: [111.655399,  34.387179], num: 0 },
          { name: "伊川县", LngAndLat: [112.429384, 34.423416], num: 0 },
          { name: "偃师区", LngAndLat: [112.787739, 34.723042], num: 0 },
        ]
        res.data.map(item => {
          _sanData.forEach(ele => {
            if (ele.name === item.districtName) ele.num = item.schoolTotal
          })
        })
        this.sanData = _sanData
      })
    },
    // 行业动态列表
    getQuerySimpleIndustryDynamics () {
      querySimpleIndustryDynamics({
        isPublish: 1,
        pageIndex: 1,
        pageSize: 3,
        type: 1
      }).then(res => {
        if (res.data.list.length > 0) {
          res.data.list.forEach(ele => {
            let data = new Date(ele.updateTime);
            const year = data.getFullYear()
            const month = data.getMonth() + 1
            const day = data.getDate()
            ele.year = year
            ele.day =  month.toString().padStart(2,'0') +'/'+ day.toString().padStart(2,'0')
          })
          this.dataSheetList = res.data.list
        }
      })
    },
    // 查询体检地址列表
    geyQueryGetHospitalList () {
      queryGetHospitalList({
        pageIndex: 1,
        pageSize: 2
      }).then(res => {
        this.hospitalListData = res.data
        let _markers = []
        res.data.forEach(item => {
          let marker = {
            lng: item.lng,
            lat: item.lat
          }
          _markers.push(marker)
        })
        this.markers = _markers
      })
    },
    // 点击体检医院
    tapClickHospital (hos) {
      this.map.center = {
        lng: hos.lng, 
        lat: hos.lat
      }
      this.mapLabel = hos.hospitalName
    },
    // 点击地图上的标记点
    lookDetail (center) {
      const hosList = this.hospitalListData
      let hosTitle = ''
      hosList.forEach(item => {
        if (item.lat == center.lat) hosTitle = item.hospitalName
      })
      window.open("https://api.map.baidu.com/geocoder?address="+hosTitle +"&output=html&src=我的位置",'_blank')
    },
    // 地图初始化
    handler ({BMap, map}) {
      map.disableScrollWheelZoom() //禁止缩放
    },
    // 跳转详情页
    tapJumpDetail (id) {
      this.$router.push({
        name:'Details',
        query: {
          id,
          type: 'about'
        }
      })
    },
    tabJumpDownload () {
      this.$router.push({
        name:'DownloadPage',
        query: {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
